<template>

  <div class="drink-store-create">
    <div class="drink-store-create-operate">
      <p class="drink-store-create-operate-title">新增饮品配方</p>
      <div class="drink-store-create-operate-content">
        <el-form ref="form" label-width="80px" size="mini">
          <el-form-item label="选取设备">
            <el-select v-model="drinkSync.deviceUuid" placeholder="请选取复制设备" filterable @change="loadDrink">
              <el-option :label="device.deviceName" :value="device.deviceUuid" v-for="device in devices"></el-option>
            </el-select>
            <el-button size="mini" type="primary" style="margin-left: 20px" plain @click="load">加载设备</el-button>
          </el-form-item>
          <el-col :span="10">
            <el-form-item label="配方名称">
              <el-input v-model="drinkSync.taskName"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="18">
            <el-form-item label="选取饮品" size="mini" :disable="drinkDisable">
              <el-transfer
                :titles="['饮品列表', '已选饮品']"
                filterable
                :filter-method="filterMethod"
                filter-placeholder="请输入饮品名称"
                v-model="drinkSync.drinkUuid"
                :data="drinks">
              </el-transfer>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item>
              <el-button type="primary" size="small" @click="handleDrinkSyncCreate">提交</el-button>
              <el-button @click="goBack" size="small">返回</el-button>
            </el-form-item>
          </el-col>

        </el-form>
      </div>

    </div>
    <div class="drink-store-create-info">
      <p class="drink-store-create-operate-title">新增操作说明</p>
      <ol class="drink-store-create-info-content">
        <li class="alarm">请确保选取设备在线</li>
        <li>选取在线设备</li>
        <li>填入配方名称</li>
        <li>选取配方中包含的饮品</li>
        <li>等待系统完成设备饮品读取</li>
      </ol>
    </div>
    <loading :show="showLoading" :text="'正在获取设备饮品，请耐心等待'"/>
  </div>

</template>

<script>

  import {deviceOnline, deviceDrink, deviceCtrlDrinkParamUpload} from '@/api/equipment/device'
  import {checkObj, fillObj} from "@/util/checkLogin";
  import {randomNum} from "@/util/random";
  import Loading from '@/components/Loading'

  import {drinkSyncParam} from '@/api/equipment/drink'

  export default {
    name: '',
    components: {
      Loading
    },
    mounted: function () {
      this.load()

    },
    data: function () {
      return {
        devices: [],
        drinkDisable: true,
        checkDrinks: [],
        drinks: [],
        showLoading: false,
        drinkSync: {
          uuid: '',
          deviceUuid: '',
          taskName: '',
          imgIndex: 0,
          drinkUuid: [],
        },
      }
    },
    methods: {
      load() {
        deviceOnline().then(res => {
          this.devices = res.data || []
          if (this.devices.length === 0) {
            this.$message.warning('无在线可用设备')
            this.$router.back()
            return
          }
          let drinkSync = this.$route.params.drink
          if (drinkSync) {
            this.drinkSync.deviceUuid = drinkSync.deviceUuid
            this.drinkSync.taskName = drinkSync.name
            this.drinkSync.imgIndex = drinkSync.imgIndex
            this.drinkSync.drinkUuid = drinkSync.drinkUuid.split(",")
            this.drinkSync.uuid = drinkSync.uuid
            this.loadDrink(drinkSync.deviceUuid, this.drinkSync.drinkUuid)
          }
        })
      },
      loadDrink(val, drinkUuid = []) {
        this.drinkSync.drinkUuid = drinkUuid
        let vue = this
        deviceDrink({uuid: val}).then(res => {
          let drinks = res.data || []
          drinks.forEach(item => {
            vue.drinks.push({
              key: item.drinkUuid,
              label: item.name,
            })
          })
        })
      },

      handleDrinkSyncCreate() {
        let check = checkObj(this.drinkSync, [
          {value: 'deviceUuid', message: '请选取设备'},
          {value: 'taskName', message: '请输入配方名称'},
        ])

        if (!check.status) {
          this.$message.warning(check.message)
          return
        }

        if (this.drinkSync.drinkUuid.length === 0) {
          this.$message.warning('请选取需要同步的饮品')
          return
        }
        this.drinkSync.imgIndex = randomNum(0, 14)
        deviceCtrlDrinkParamUpload(this.drinkSync).then(res => {
          this.$message.success('创建成功')
          this.showLoading = true
          this.queryDrinkSync(res.data)
        })
      },

      queryDrinkSync(uuid){
        let vue = this
        setTimeout(function () {
          drinkSyncParam({uuid: uuid}).then(res => {
            let drinks = res.data || []
            if (!drinks) {
              return;
            }
            vue.showLoading = false
            vue.$router.replace({name: 'marketing.drink.edit', params: {uuid: uuid, drink: drinks}})
          })
        }, 5 * 1000)
      },
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1;
      },
      goBack() {
        this.$router.back()
      },

    },
  }
</script>

<style lang="scss">
  .drink-store-create {
    display: flex;

    .drink-store-create-operate-title {
      font-weight: 500;
      margin-bottom: $margin;
      height: $margin;
      line-height: $margin;
    }

    .drink-store-create-operate {
      flex: 1;
      @include set_bg_color($background-color-light, $background-color-dark);
      padding: $padding;
      border-radius: $border-radius;
      display: flex;
      flex-direction: column;

      .drink-store-create-operate-content {
        flex: 1;
        display: -webkit-box;
        -webkit-box-orient: horizontal;
        -webkit-box-pack: center;
        -webkit-box-align: start;

        display: -moz-box;
        -moz-box-orient: horizontal;
        -moz-box-pack: center;
        -moz-box-align: center;
      }
    }

    .drink-store-create-info {
      width: 24%;
      @include set_bg_color($background-color-light, $background-color-dark);
      padding: $padding;
      margin-left: $margin;
      border-radius: $border-radius;
    }

    .drink-store-create-info-content {
      font-size: 12px;
      @include set_bg_color(rgba(124, 198, 255, 0.56), $background-color-dark-1);
      padding: $padding-8;
      border-radius: $border-radius;
      list-style-type: none;
      counter-reset: sectioncounter;

      li:before {
        content: counter(sectioncounter) "、";
        counter-increment: sectioncounter;
      }
    }

    .drink-store-edit-content {
      padding: 0 $padding-8 $padding-8 $padding-8;

      .el-input-number__increase, .el-input-number__decrease {
        border-color: #D8DDE3;
      }
    }

    .alarm {
      color: $btn_color;
    }

    .error {
      color: $btn_sec_color !important;
    }

    .el-tag + .el-tag {
      margin-left: 10px;
    }

    .drink-store-edit-item {
      margin-bottom: $padding;

      .drink-store-edit-item-title {
        margin-bottom: $padding-8;

        span {
          float: right;
          font-size: 12px;
          display: inline-block;
          padding-left: $padding-8;
          color: $_theme_color;

          i {
            font-size: 14px;
          }
        }
      }

      .drink-store-edit-item-content {
        padding: $padding-8;
        @include set_bg_color($background-color-light-2, $background-color-dark-1);
      }

      .drink-height {
        height: 240px;
        overflow-y: scroll;
      }
    }

    .delete-drink-store {

      i {
        font-size: 14px;
      }
    }
  }

  body.dark {
    .drink-store-edit-content {
      .el-input-number__increase, .el-input-number__decrease {
        border-color: #889DB8;
      }
    }

  }
</style>
