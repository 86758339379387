<template>
    <div class="loading-mask" style="" v-show="show">
        <div class="loading-spinner">
            <svg class="loading-flash">
                <rect x="28" y="50" width="2" rx="2" ry="2" height="50" class="loading-path"/>
                <rect x="38" y="50" width="2" rx="2" ry="2" height="50" class="loading-path"/>
                <rect x="48" y="50" width="2" rx="2" ry="2" height="50" class="loading-path"/>
                <rect x="58" y="50" width="2" rx="2" ry="2" height="50" class="loading-path"/>
                <rect x="68" y="50" width="2" rx="2" ry="2" height="50" class="loading-path"/>
            </svg>
            <p class="loading-mask-text">{{text}}</p>
            <el-button v-if="textCloseFn !=null" @click="textCloseFn" size="mini">{{textClose}}</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'loading',
        components: {},
        props: {
            show: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                default: `拼命加载中`
            },
            textClose: {
                type: String,
                default: `关闭`
            },
            textCloseFn: {
                type: Function,
                default: null,
            }
        },
        mounted: function () {
        },
        data: function () {
            return {}
        },
        watch: {
            show: function (val) {
                this.$emit('update:show', val)
            }
        },
        methods: {}
    }
</script>

<style lang="scss">
    .loading-mask {
        position: fixed;
        z-index: 10000;
        background-color: hsla(0, 0%, 0%, 0.7);
        margin: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity .3s;
        .loading-spinner {
            top: 50%;
            margin-top: -21px;
            width: 100%;
            text-align: center;
            position: absolute;
            .loading-flash {
                height: 50px;
                width: 100px;

            }
            .loading-path {
                stroke: $_theme_color;
                fill: transparent;
                stroke-width: 3;
                -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
                animation: stretchdelay 1.2s infinite ease-in-out;
                /*stroke-linecap: round;*/
            }

            .loading-flash rect:nth-child(1) {
                -webkit-animation-delay: -1.1s;
                animation-delay: -1.1s;
            }

            .loading-flash rect:nth-child(2) {
                -webkit-animation-delay: -1.0s;
                animation-delay: -1.0s;
            }

            .loading-flash rect:nth-child(3) {
                -webkit-animation-delay: -0.9s;
                animation-delay: -0.9s;
            }

            .loading-flash rect:nth-child(4) {
                -webkit-animation-delay: -0.8s;
                animation-delay: -0.8s;
            }

            .loading-flash rect:nth-child(5) {
                -webkit-animation-delay: -0.7s;
                animation-delay: -0.7s;
            }

            @-webkit-keyframes stretchdelay {
                0%, 40%, 100% {
                    -webkit-transform: scaleY(0.4)
                }
                20% {
                    -webkit-transform: scaleY(0.6)
                }
            }

            @keyframes stretchdelay {
                0%, 40%, 100% {
                    transform: scaleY(0.4);
                    -webkit-transform: scaleY(0.4);
                }
                20% {
                    transform: scaleY(.6);
                    -webkit-transform: scaleY(.6);
                }
            }
            .loading-mask-text {
                color: $_theme_color;
                margin-top: 2px;
            }

            .el-button{
                margin-top: $padding;
            }
        }
    }
</style>
